import React from "react";
import { Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles, styled } from "@mui/styles";
import dayjs from "dayjs";

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Lato',
    lineHeight: '20px',
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
    transform: 'translateX(200px) !important' // Adjust this value to move the arrow towards the left'
  }
}));


const useStyles = makeStyles((theme) => ({
  alertDetailContainer: {
    padding: "5px !important",
    fontFamily: "Lato",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "60px",
  },
  icdCodes: {
    minHeight: "235px",
    overflow: "auto"
  },
  icdRow: {
    width: "100%",
    height: "35px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(231, 231, 231, 0.5)",
    marginTop: "5px",
    padding: "0 10px !important",
    fontFamily: "Lato",
  },
  actionDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    marginBottom: "5px",
    marginRight: "5px",
  },
  fonthighlight: {
    color: "#0066CB",
    fontWeight: 600
  },
  fonthighlight2: {
    // color: "#0066CB"
    fontWeight: 600

  },
  backHighLight: {
    backgroundColor: "#FFFF00"
  },
  pastClaimContainers: {

  },
  pastClaimInnerContainers: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pastClaimsHeading: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    color: '#0066CB'
  },
  pastClaimsMainHeading: {
    color: "#141414",
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    margin: '5px 0px'
  },
  keyword: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: "#F5EA14",
    padding: "0px 2px",
    borderRadius: "2px"
  }
}));

const SmartAlertDetails = ({ alert, isMissingCode, selectedIndex }) => {
  const classes = useStyles();

  const truncateText = (text, wordLimit = 150) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const highlightTerms = (text, terms) => {
    let highlightedText = text;
    terms.forEach(term => {
      const termRegex = new RegExp(`(${term.termSearched})`, 'gi'); // Regex to match the term case-insensitively
      highlightedText = highlightedText.replace(termRegex, `<span class="${classes.backHighLight}">&nbsp;$1&nbsp;</span>`);
    });
    return highlightedText;
  }

  const date = alert?.identified_date || '';
  
  return (
    <Box className={classes.alertDetailContainer}>
      {!isMissingCode && (
        <>
          {
            alert?.additionalDetails?.notes?.length > 0 ?
              alert?.additionalDetails?.notes?.map((note) => {
                const noteDate = note?.date ? dayjs(note?.date).format('MM/DD/YYYY') : '';

                const highlightedText = highlightTerms(note?.note_text || '', note?.searchTerms || []);
                const noteTextForShort = truncateText(note?.note_text || '');
                const highlightedTextShort = highlightTerms(noteTextForShort, note?.searchTerms || []);
                return (
                  <CustomToolTip
                    placement="top-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-30, 0],  // Adjust the first value to move the tooltip left or right
                          },
                        },
                      ],
                    }}
                    title={
                      <React.Fragment>
                        <span className={classes.fonthighlight}>Identified from a note on: {noteDate} </span>
                        <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
                      </React.Fragment>
                    }>
                    <Typography>
                      <Typography>
                        <span className={classes.fonthighlight}>Identified from a note on: </span> {noteDate} &nbsp;
                        {alert?.additionalDetails?.notes?.length > 0 && <span dangerouslySetInnerHTML={{ __html: highlightedTextShort }} />}
                      </Typography>

                    </Typography>
                  </CustomToolTip>
                )
              })
              :
              <Typography>
                <span className={classes.fonthighlight}>Identified from the {alert?.sdoh_data?.source?.replace(/_/g, " ")} on: </span> {date}
              </Typography>
          }
        
        </>
      )}

      {isMissingCode && (
        <>
          <Typography>
            <Typography>
              <span className={classes.fonthighlight}>ICD Code: </span> {[alert?.missing_code_data?.ICD_CODE, alert?.missing_code_data?.ICD_description].join(' - ')}
            </Typography>
            <Typography>
              <span className={classes.fonthighlight}>Date of claim: </span> {date}
            </Typography>

          </Typography>
        </>
      )}
    </Box>
  );
};

export default SmartAlertDetails;
