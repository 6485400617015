import React, { useEffect } from 'react';
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import { notification_type } from '../../constants';

const useStyles = makeStyles((theme) => ({
    mainBox: {
      maxWidth: "450px",
      margin: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    },
    listContainer: {
      backgroundColor: "#E7E7E7",
      width: "100%",
      maxWidth: "440px",
      borderRadius: "5px",
      padding: "5px 10px !important",
    },
    selectedListContainer: {
      backgroundColor: "#E3F5FF !important",
    },
    triangle: {
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "20px solid transparent",
      transform: "rotate(90deg)",
    },
    selectedTriangle: {
      borderBottom: "20px solid #E3F5FF !important",
    },
    detailsContainer: {},
    descriptionText: {
      fontFamily: "Lato",
      fontWeight: 500,
      fontSize: "14px !important",
      lineHeight: "21px",
    },
    sourceContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sourceFont: {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "12px !important",
      lineHeight: "20px",
    },
    sourceDate: {
      color: "#797979",
    },
    sourceName: {
      color: "#0066CB",
    },
    recommendedContainer:{
      marginTop: "10px",
      marginRight: "10px"
    },
    recommendedDetailsTitle:{
        padding: '7px 10px'
    },
    recommendedDetailsInnerContainer1:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: "#E7E7E7",
    },
    recommendedDetailsInnerContainer2:{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 10px !important'
    },
    toggleButton: {
      border: "0px !important",
      margin: "5px",
      borderRadius: "5px !important",
    },
    upTrue: {
      backgroundColor: "rgb(104, 235, 141) !important",
    },
    upFalse: {
      backgroundColor: "rgba(104, 235, 141, 0.3) !important",
    },
    downTrue: {
      backgroundColor: "rgb(235, 104, 104) !important",
    },
    downFalse: {
      backgroundColor: "rgba(235, 104, 104, 0.3) !important",
    },
    gridContainer:{
      height: "260px",
      overflow: "auto",
      marginTop: "0"
    },
    skeletonContainer:{
      padding: "0 2% !important"
    },
    noteText: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      color: "#141414"
    },
    dateTitle: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "20px",
      color: "#0066CB",
    },
    dateSpan: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      color: "#141414",
    }
  }));

  const RecommendedList = ({
    id,
    title,
    date,
    index,
    selectedIndex,
    handleClick,
  }) => {
    const classes = useStyles();
    return (
      <Box className={classes.mainBox} onClick={() => handleClick(index, id)}>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedListContainer : ""
          } ${classes.listContainer}`}
        >
          <Box className={classes.detailsContainer}>
            <Typography className={classes.descriptionText}>
              {title}
            </Typography>
          </Box>
          <Box className={classes.sourceContainer}>
            <Typography className={`${classes.sourceFont} ${classes.sourceDate}`}>
              {date}
            </Typography>
          </Box>
        </Box>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedTriangle : ""
          } ${classes.triangle}`}
        ></Box>
      </Box>
    );
  };

const IpErLabResultsTab = ({selectedNotification}) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState([])
    const { state } = useContext(WcchcPatientSummaryContext);
    const [alerts, setAlerts] = useState({})
    const [allAlerts, setAllAlerts] = useState([]);

    const labResults = ["LAB_RESULTS"];

    const createUniqueMapping = (dataArray) => {
      const result = {};
  
      dataArray.forEach(item => {
        if (labResults.includes(item.type)) {
            const label = labResults[0];
                if (!result[label]) {
                    result[label] = [];
                }
                result[label].push(item);
        }
      });
  
      return result;
    };

    const uniqueValue = (data) => {
      const uniqueNotes = {};

      data.forEach(entry => {
          const noteType = entry.type;
          if (labResults.includes(noteType) && !uniqueNotes[noteType]) {
              uniqueNotes[noteType] = {
                  date: entry.note_date,
                  type: noteType
              };
          }
      });

      return uniqueNotes
    }

    useEffect(() => { 
      if (state.allSmartAlerts.length) {
        const data = uniqueValue(state.allSmartAlerts);
        setAlerts(data);
        const newData = createUniqueMapping(state.allSmartAlerts);
        setAllAlerts(newData)
        setSelectedItem(newData[selectedNotification.type || "LAB_RESULTS"] || [])
        labResults.includes(selectedNotification.type) && setSelectedIndex(labResults.findIndex((i) => i === selectedNotification.type))
      }  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.allSmartAlerts, selectedNotification]); 

    const handleClick = (index, type) => {
        setSelectedIndex(index);
        setSelectedItem(allAlerts[type])
    };

    const extractUnit = (labValue) => {
      const match = labValue.match(/[\d.]+\s*(.*)/);
      return match ? match[1] : null; // Return the unit if matched, else null
    }

    return(
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.gridContainer}
      >
        {
          state.isFetchingAllSmartAlerts ? (
            <Grid item xs={12}>
              {new Array(5).fill(0).map((_, index) => (
                <Box className={classes.skeletonContainer}>
                  <Skeleton key={index} height={50} sx={{width: "100%"}}/>
                </Box>
              ))}
            </Grid>
          )
          :
          Object.keys(alerts).length === 0 ?(
            <>
              <Grid item xs={6}>
                <Box className={classes.mainBox}>
                  <Typography className={classes.descriptionText}>
                    No updated Lab results data available for past one year
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
            </>
          )
          :
          <>
          <Grid item xs={4}>
            {labResults.map((type, index) => { 
              if(!alerts[type]) return null;
              
              return (
                <RecommendedList
                  key={index}
                  index={index}
                  selectedIndex={selectedIndex}
                  handleClick={() => handleClick(index, alerts[type].type)}
                  title={notification_type[alerts[type].type]}
                  date={state.lastUpdatedDate}
                />
              );
            })}            
          </Grid>
          <Grid item xs={8} my={3} >            
            {selectedItem?.length > 0 ? selectedItem?.map((item,index) => {
              const unit = extractUnit(item?.lab_value);
              return (
                <div key={index} style={{ paddingBottom: "15px" }} >
                  <Typography className={classes.noteText} >Patient had an abnormal lab result for {item.lab_test}.</Typography>
                  <Typography className={classes.dateTitle} >Date of Test: <span className={classes.dateSpan} >{item?.identified_date || '-'}</span></Typography>
                  <Typography className={classes.dateTitle} >Lab Value: <span className={classes.dateSpan}>{item?.lab_value}</span></Typography>
                  <Typography className={classes.dateTitle} >Reference Range: <span className={classes.dateSpan}>{item?.lab_range} {unit}</span></Typography>
                </div>
            )
            }) : <>
            <Typography className={classes.noteText} >No Lab results data available</Typography>
            </>}
          </Grid>
          </>
        }
      </Grid>
    )
};

export default IpErLabResultsTab