import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Skeleton,
  Box, 
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import NoDataMessage from "../../WcchcPatientSummary/NoDataMessage";
import SortButton from "../../WcchcPatientSummary/SortButton";
import { sortData } from "../../../Utils/wcchcPatientSummary";

const useStyles = makeStyles((theme) => ({
  tableMainContainer: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
  },
  tableContainer: {
    boxShadow: "none",
    maxHeight: "270px",
  },
  tableContainerModal: {
    boxShadow: "none",
    maxHeight: "calc(100vh - 110px)",
  },
  table: {
    paddingLeft: "20px !important",
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
    "& > thead > tr > th:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left !important"
    },
    "& > tbody > tr > td:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left",
    },
    "& > tbody > tr > td:last-child": {
      textAlign: "left",
    },
  },
  tableRow: {
    "& > td:nth-child(1)": {
      fontWeight: 600,
    },
  },
  tableHeaderCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#7D7B7B",
    padding: "8px 6px",
    textAlign: "left",
  },
  tableHeaderCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#060606",
    padding: "8px 6px",
    borderBottom: "0.5px solid #000000 !important",
    textAlign: "left",
  },
  tableDataCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 6px",
    borderBottom: "none",
    textAlign: "center",
  },
  tableDataCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 6px",
    textAlign: "center",
  },
  tableEmptyCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 10px",
    borderBottom: "none",
    textAlign: "center",
  },
  label:{
    fontWeight: "700",
    fontFamily: "Lato"
  }
}));

const columns = [
  {label: "Inpatient Visit/ER visit", sort: false, name: "name"},
  {label: "Date", sort: true, name: "date"},
  {label: "Facility Name", sort: false, name: "facilityName"},
];

const HospitalAdmitsAndErVisitTable = ({ modal = false, maxHeight }) => {
  const [visitData, setVisitData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [currentSorting, setCurrentSorting] = useState({});
  const [sortConfig, setSortConfig] = useState([
    { key: 'date', direction: 'desc' },
  ]);

  const classes = useStyles();
  const classNameForCell = modal ? classes.tableDataCellModal : classes.tableDataCell;


  const { state, dispatch } = useContext(WcchcPatientSummaryContext);

  const sortHandler = (key,sortType) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        const newDirection = sortType;
        return prevConfig.map((config) =>
          config.key === key ? { ...config, direction: newDirection } : config
        );
      } else {
        return [...prevConfig, { key, sortType, direction: sortType }];
      }
    });
    setCurrentSorting({key,sortType, direction: sortType})
  }

  const sortTableData = () => {
    let sortedData = [...finalData];
    const { direction } = currentSorting;
    const data = sortData(sortedData, 'visit_date', direction)

    setVisitData([...data]);
  };

  useEffect(() =>{
    dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {hospitaladmits: state.visits.length} });
    setVisitData(state.visits)
    setFinalData(state.visits)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(state.visits)])

  useEffect(() =>{
    if(Object.keys(currentSorting)?.length){
      sortTableData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortConfig])

  return (
    <Paper className={classes.tableMainContainer}>
      <TableContainer 
        className={modal ? classes.tableContainerModal : classes.tableContainer}
      >
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          {visitData?.length ? <TableHead>
            <TableRow>
              {columns.map(({label, sort, name}, index) => (
                <TableCell
                  key={index}
                  className={
                    modal
                      ? classes.tableHeaderCellModal
                      : classes.tableHeaderCell
                  }
                >
                {
                  sort ?
                  <Box 
                      sx={{
                        display: 'flex',
                        justifyContent: index ? "center": "flex-start", 
                        alignItems: 'center', 
                        gap: "15px"
                      }}
                  >
                    <Typography className={classes.label}>{label}</Typography>
                    <SortButton onClick={sortHandler} name={name} sortConfig={sortConfig}/>
                  </Box>
                  :
                  label
                }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          :
          null
          }
          <TableBody>
            {state.isFetchingVisits ? (
              new Array(6).fill(0).map((_, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  className={classes.tableRow}
                >
                  <TableCell colSpan={2} className={classes.tableEmptyCell}>
                    <Skeleton variant="rounded" width="100%" height={25} />
                  </TableCell>
                </TableRow>
              ))
            ) : visitData?.length ? (
              visitData?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.tableRow}
                  >
                    <TableCell className={classNameForCell}>
                      {row.visit_type === "ER" ? "Patient ER Visit" : "Inpatient Visit" ?? "Not Available"} 
                    </TableCell>
                    <TableCell className={classNameForCell}> {row.visit_date ?? "Not Available"}</TableCell>
                    <TableCell className={classNameForCell}> {row?.facility_name ?? "Not Available"}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.tableRow}
              >
                <TableCell colSpan={3} className={classes.tableEmptyCell}>
                  <NoDataMessage 
                    message={"No data available"}
                  />                
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HospitalAdmitsAndErVisitTable;
